@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
* Fonts: 
* We are switching to using primarily Inter font for all sizes smaller than xl,
* and we are using Gilroy only for xl and larger.
* To align with figma, for all Inter fonts: simply use text-<size> and font-<weight> if needed
* for all gilroy fonts, do the same, but add in font-gilroy font-bold as well 
* Currently for gilroy we only have regular, medium, semiboldm and bold pre defined, but others can be imported if needed

* Note: by default, all fonts with no styling will be font-inter, font-regular, text-base, and text-gray-900
* Note: Inter is imported by default in tailwind.
*/

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./public/fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./public/fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./public/fonts/Gilroy/Gilroy-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./public/fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
}

/* These are for react PDF  */
.react-pdf__Page__textContent.textLayer {
  display: none;
}
.react-pdf__Page__annotations.annotationLayer {
  display: none;
}

.decimal-li {
  padding-left: 1em;
  text-indent: -2em;
}

.decimal-li::before {
  counter-increment: section;
  content: counters(section, ".") ". ";
}

/* This is to hide the number arrows on inputs of type number <input/> */
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
